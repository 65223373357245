/* Source: https://css-tricks.com/making-pure-css-playpause-button/ */
.play-pause-button {
  border: 0;
  background: transparent;
  width: 0;
  height: 1.5rem;

  border-color: transparent transparent transparent palegreen;
  transition: 100ms all ease;
  cursor: pointer;

  border-style: solid;
  border-width: 1rem 0 1rem 1.5rem;
}

.play-pause-button.pause {
  border-style: double;
  border-width: 0px 0 0px 1.5rem;
  border-color: transparent transparent transparent gray;
}

.play-pause-button:hover {
  opacity: 0.9;
}

#waveform-container {
  display: grid;
  width: 90%;
  grid-template-columns: 45px auto;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
