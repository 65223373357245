.acknowledgements-spacer {
  height: 100vh;
  height: calc(var(--full-screen-vh, 1vh) * 100);
  height: 100lvh;
  position: relative;
}

.acknowledgements-container {
  width: 100%;
  display: flex;
}

.acknowledgements-content {
  width: 100%;
  padding: 1rem;
  font-size: 1.125rem;
}

@media (max-width: 768px) {
  .acknowledgements-content {
    font-size: 1.25rem;
  }
}

@supports (initial-letter: 2) or (-webkit-initial-letter: 2) {
  .acknowledgements-content p:first-of-type::first-letter {
    -webkit-initial-letter: 2;
    initial-letter: 2;
    color: black;
    margin-right: 0.75em;
  }
}

.acknowledgements-content ul {
  margin-block: 2em;
}
