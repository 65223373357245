.drop-item-container {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-page-wrapper {
  height: 500px;
  height: 100vh;
  height: calc(var(--full-screen-vh, 1vh) * 100);
  height: 100lvh;
  display: flex;
  justify-content: center;
  align-items: center;
}
