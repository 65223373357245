.nav {
  position: absolute;
  top: 0;
  right: 0;
}

.nav .list {
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.nav .list .item {
  height: 44px;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: calc(0.5rem - 2px);
  font-weight: 700;
}

.nav .list .item:hover {
  background-color: hsl(240 4.8% 95.9%);
}

.nav .list .item > a {
  color: inherit;
  text-decoration: none;
}
