.project-title {
  font-size: 3rem;
  line-height: 1;
  font-weight: 400;

  text-align: center;
  /* h1 reset */
  margin-block-start: 0;
  margin-block-end: 0;
}

@media (max-width: 768px) {
  .project-title {
    font-size: 2.5rem;
  }
}

.project-header {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 0.75em;
  align-items: baseline;
}

.project-header .project-title {
  grid-column: 2;
  grid-row: 1;
  width: max-content;
  justify-self: center;
}

.project-header .project-title-info {
  grid-column: 3;
  grid-row: 1;
  width: min-content;
  height: min-content;
  padding: 0;
  background: none;
  border: 0;
  cursor: pointer;
  transition: fill 0.1s ease-in;
  position: relative;
}
.project-header .project-title-info:hover svg {
  transition: fill 0.2s ease-out;
  fill: color-mix(in srgb, #303030 80%, red);
}

.project-header .project-title-info svg {
  width: 1.5em;
  display: block;
}
