#info-wrapper {
  min-height: 100vh;
  min-height: calc(var(--full-screen-vh, 1vh) * 100);
  min-height: 100lvh;
  display: flex;
  align-items: center;
}

#info-container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  line-height: 1;
}

#info-container a {
  padding: 0 8px;
}

.info-element {
  display: flex;
  flex-direction: column;
  align-items: center;

  font-weight: 300;
  font-size: 1.5rem;
  text-align: center;

  /* Reset anchor tag */
  text-decoration: none;
  color: #353532;
}

@media (max-width: 768px) {
  .info-element {
    font-size: 1rem;
  }
}

.info-element img {
  width: 100%;
  height: auto;
}

.info-element:hover {
  box-shadow: 0 0 4px lightgray;
  border-radius: 4px;
}

.info-element:visited {
  color: #353532;
}
