.content-with-audio-container {
  display: grid;
  gap: 1rem;
}

@media (max-width: 768px) {
  .content-with-audio-container {
    gap: 1.5rem;
  }
}

.content-with-audio-container #waveform-container {
  grid-area: var(--waveform-grid-area, auto);
}

.content-with-audio-title {
  display: flex;
}
