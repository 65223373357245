#welcome-wrapper {
  display: flex;
  justify-content: center;
}

#welcome-content-container {
  width: 100%;
  max-width: 1200px;
}

#welcome-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  height: 100vh;
  /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  height: 100svh;
}

@supports (display: grid) {
  #welcome-container {
    justify-content: unset;

    display: grid;
    grid-template-rows: 1fr 1.684fr 1fr;
  }
}

#my-introduction,
#welcome-video-container {
  place-self: center;
}

#scroll-down-element {
  height: min(100px, 60%);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  place-self: end;
}

.scroll-down-element-line {
  margin-bottom: 4px;
  border-right: 1px solid black;
  height: min(60px, 80%);
  width: 2px;
  animation: 3.2s ease-in-out infinite fadebottom;
  -webkit-animation: 3.2s ease-in-out infinite fadebottom;
}

@keyframes fadebottom {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }

  25% {
    opacity: 0;
    transform: translateY(20px);
  }

  30% {
    opacity: 0;
    transform: translateY(0px);
  }

  45% {
    opacity: 1;
    transform: translateY(0px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

#scroll-down-element-text {
  margin: 0.5rem 0 1rem 0;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1rem;
}

#my-introduction {
  font-size: max(5vw, 3rem);
  font-weight: 500;
  line-height: 1;
  color: #1b1b1b;
}

#welcome-video-container {
  width: 100%;
  max-width: 600px;
  aspect-ratio: 16/9;
  background: url("../public/first_frame.jpg");
  background-color: lightgray;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
}

/* TODO: add different font-size for mobile */
#content-container {
  display: flex;
  justify-content: center;
  line-height: 1.6;
  font-size: 1em;
}

@media (max-width: 768px) {
  #content-container {
    font-size: 1.125em;
  }
}

#project-content {
  /* 
   * TODO: the max-width here is 800px because I do not want it to go bigger then that
   * if it does it is possible that the iframes start scrolling. The width of the container is ultimatly 
   * determined by widest iframe. But that logic is not really clear right now 
   */
  max-width: 800px;
}

.project-introduction #container {
  margin-bottom: 18%;
  max-width: 800px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.project-introduction #title {
  text-align: center;
  font-size: max(4vw, 2rem);
  font-weight: 400;
  justify-content: center;
  align-items: center;
}
