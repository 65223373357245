.post {
  padding: 2rem 1rem;
  max-width: 60ch;
  margin: auto;
  line-height: 1.6;
  font-size: 1.1rem;
}

@media (min-width: 768px) {
  .post {
    font-size: 1.15rem;
    padding: 2rem;
  }
}

.post :is(p, blockquote) {
  margin-block: 1rlh;
}

.post h1 {
  margin-block-start: 0.2lh;
  margin-block-end: 0.5lh;
}

.post h2 {
  margin-block-start: 0.3lh;
  margin-block-end: 0.6lh;
}

.post h3 {
  margin-block-start: 0.4lh;
  margin-block-end: 0.7lh;
}
