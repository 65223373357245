#montepoeli-video {
  height: 100%;
  background-color: lightgray;
  background-size: contain;
}

#montepoeli-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

#montepoli-figure {
  margin: 0;
  aspect-ratio: 304.812/659.797;
  height: 80vh;
  height: calc(var(--full-screen-vh, 1vh) * 80);
  height: 80lvh;
}

#montepoeli-content > figcaption {
  margin-top: -9px;
  line-height: 0;
}

progress[value] {
  appearance: none;
  border: none;
}

progress[value]::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}
/* I don't really get how the coloring works here
 * I copied what they did here https://css-tricks.com/html5-progress-element/
*/
progress[value]::-webkit-progress-value {
  background-image: -webkit-linear-gradient(
      -45deg,
      transparent 33%,
      lightgreen 33%,
      lightgreen 66%,
      transparent 66%
    ),
    -webkit-linear-gradient(top, lightgreen, lightgreen),
    -webkit-linear-gradient(left, lightgreen, lightgreen);

  transition: width 0.5s linear;
}

#progress {
  height: 7px;
  vertical-align: 0;
  width: 100%;
}
